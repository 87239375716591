<template>
	<div id="horse_infos">
		<div class="row">
			<div class="col-12 col-sm">
				{{ recapContent  }}
			</div>
		</div>

        <div v-if="locationContent" class="row">
            <div class="col-12 col-sm">
                <small class="horse_recap">
                    <font-awesome-icon :icon="['far', 'map-marker-alt']" /> {{ locationContent }}
                </small>
			</div>
        </div>
	</div>
</template>


<script type="text/javascript">
	import Horse from '@/mixins/Horse.js'

	export default {
		props: [
			'horse',
            'presence'
		],
		mixins: [
			Horse,
		],
        data() {
            return {
                horse_pedigree: null
            }
        },
        created() {
            this.preinit_component()
        },
        methods: {
            async preinit_component() {
				this.horse_pedigree = await this.getHorsePedigree(this.horse.horse_id)
            }
        },
		computed: {
            recapContent() {
                if(!this.horse) return ''

                let temp = []
                temp.push(this.horse.sexe_code)

                if(this.horse.horse_datenaissance) {
                    if(this.showFullBirthdate) {
                        temp.push(this.horse.horse_datenaissance_formated)
                    }
                    else {
                        const date = new Date(this.horse.horse_datenaissance)
                        temp.push(date.getFullYear())
                    }
                }
                if(this.horse.robe_code) {
                    temp.push(this.$options.filters.lowercase(this.horse.robe_label))
                }
                if(this.horse_pedigree) {
                    const tmp = [
                        this.$options.filters.multiCapitalize(this.horse_pedigree.pedigree_nom_1),
                        this.$options.filters.multiCapitalize(this.horse_pedigree.pedigree_nom_2)
                    ]
                    temp.push(tmp.join(' x '))
                }

                return temp.join(', ')
            },
            partsContent() {
                if(!this.horse) return ''
                if(!this.horse.tiers_horse_part) return ''
                return this.$t('horse.propriete_pourcentage', { percent: this.horse.tiers_horse_part.tiershorsepart_propriete })
            },
            locationContent() {
                if(!this.presence) return ''
                return this.presence.mouvement_label
            },
            showFullBirthdate() {
                if(this.horse.horse_categories.length === 0) return false

                const ids_monceaux = [2, 3] // oui
                const temp = this.horse.horse_categories.find(c => ids_monceaux.indexOf(c.categorie_id) > -1)
                return !!temp
            }
		}
	}
</script>
